if(!window.vars){
    window.vars = {};
}

if(!window.utils){
    window.utils = {};
}


utils.encodeHtml = function(str){
    let div = document.createElement('div');
    div.innerHTML = str;
    return div.innerText;
};



if(!window.functions){
    window.functions = {};
}

window.functions.showArticleAll = function () {
    let $this = $(this);
    $this.parent().removeClass('article-mask');
};
$(function () {
    $('.modal-box .close').on('click',function () {
        $('.modal-box').fadeOut();
        $('.mask').fadeOut();
    });

    $('body').on('click','[data-target]',function () {
        showMask.call(this);
    });
});

function showMask(el){
    el = el || '#' + $(this).data('target');
    $(el).fadeIn();
    $('.mask').fadeIn();
}


$(function () {
    $('.btn-link').on('click',function () {
        window.location.href = $(this).data('url');
    });

    $('.article-mask').each(function(){
        let $el = $(this);
        if($el.height() > 260){
            $el.addClass('active');
        }
    });

    $('.show-more').on('click',function () {
        window.functions.showArticleAll.call(this);
    });
});

